*{
    padding: 0;
    margin: 0;
}

.horizontalMenu{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0px 8px 0px;
}

.verticalMenu{
    height: 98vh;
    display: flex;
    flex-direction: column;

}

.verticalMenu_options{
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 3rem;
    align-items: center;
    padding: 0.5rem 0 0 0;
}

.cards_container{
    max-width: 340px;
    width: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 10px 5px;
    gap: 14px;
    max-height: 33rem;
}

.bar_div{
    width: 100%;
    background-color: #000000;
}

.image_div{
    width: 30px;
    transition: .3s ease-in-out all;
}

.image_div:hover{
    transform: translateX(125px,0);
}

.icon{
    width: 100%;
}
.cursorPointer{
    cursor: pointer;
}

.menu_bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.bottomImg_div{
    width: 40px;
    padding: 3px;
    border-radius: 5px;
    background-color: #292929;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 3px #181818;
}

.bottomImg_div .icon{
    width: 100%;
}

.content_div{
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.deleteBackground{
    background: transparent;
}


.scroll_menu{overflow: hidden; overflow-y: auto;direction: rtl; text-align: end;}
.scroll_menu::-webkit-scrollbar {-webkit-appearance: none;}
.scroll_menu::-webkit-scrollbar:vertical {width:8px;}
.scroll_menu::-webkit-scrollbar-thumb {background-color:var(--bg-containers);border-radius: 20px;}