@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family:'Hind Siliguri', sans-serif;
}

.baseBackground{
    height: 100vh;
    background-color: #343434;
    color: #f3f3f3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.renderedComponentDiv{
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TitleFirstWord{
    font-size: 6rem;
    font-weight: 600;
    line-height: 70px;
}

.WholeTitle{
    font-size: 3.7rem;
    font-weight: 500;
    line-height: 60px;
}

.greetingDiv{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.greetingImage{
    width: 45%;
    padding: 1%;
    margin: 0;
    position: absolute;
    z-index:0;
    left: 27.5%;
    bottom: 6%;
}

.title_div{
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 8%;
    top: 10%;
}

.SenaImage{
    width: 20%;
    padding: 1%;
    margin: 0;
    position: absolute;
    z-index:0;
    top: 0;
    right: 0;
}

.rendered{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10rem;
}