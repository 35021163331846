



@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');


:root{
  --bg-page:#343434;
  --bg-containers: #292929;
  --orange-all: #ff6719;
  --bg-matchCard: #1b1b1b;
  --titles-color: #f3f3f3;
  --text-color: #777777;
  --navbar-bar: #000000;
  --shadow-color1: #181818;
  --shadow-color2: #ff5500;
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Hind Siliguri', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #343434;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.position_elements_menu{display: flex; height: 100vh;}