

*{
    margin: 0;
    padding: 0;
}

.reusableBtn{
    border: none;
    border-radius: 3px;
    padding: .4rem 6.1rem;
    background-color: #ff6719;
    box-shadow: #181818 0px 0px 12px;
    color: #f3f3f3;
}

.reusableBtn:hover{
    transition: all .3s ease-in-out;
    box-shadow: #ff5500 0px 0px 6px;
}