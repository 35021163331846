.gameManagement{display: flex; flex-direction: column; width: 80%; gap: 2%; padding: 1% 1% 1% 1%;}

.match_view{display: flex; align-items:top; justify-content: space-between; width: 90%; margin: 3%;}

.matchMarker{width: 30%; display: flex; align-items: top; justify-content:space-between; flex-direction: column;}

.matchMarker input{ padding: 15px 30px; box-shadow: none;}

.matchMarker h1{font-size: 7rem;}

