*{
    padding: 0;
    margin: 0;
    text-decoration: none;
}

p{
    color: #777777;
}

h4{
    color: #f3f3f3;
}


