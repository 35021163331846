.titleStatistics{
    margin-left: 1.875rem;
}

.containerStatistics{
    color: #f3f3f3;
    width: 80%;
    border: 0.125rem;
    border-radius: 0.625rem;
    box-shadow: 0px 0px 0.75rem #181818;
    background-color: #1b1b1b;
    margin: 0.3% 0.3% 0.3% 1%;
}

.contentTextStatisticalCards{
    display: flex;
    gap: 0.3125rem;
}

.containerCards{
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    padding: 3%;
}

.containerCardOne{
    display: flex;
    flex-wrap: wrap;
    gap: 2.1875rem;
    flex-direction: column;
    width: 46%;
}
.containerCardTwo{
    width: 46%;
}
.contentTextStatisticalCardsAlls{
    line-height: 1.5625rem;
}

.styleCard{
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
}