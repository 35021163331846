*{
    padding: 0;
    margin: 0;
}

.login_div{
    padding: 40px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    /* align-items: center; */
    gap: 2rem;
    
}

.title_login_form {
    font-size: 2em;
}

.login_div h3{
    font-weight: 400;
}

.infoInputDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.4rem;
}

.line_div{
    width: 100%;
    height: 5px;
    background-color: #ff6719;
    border-radius: 0px 0px 10px 10px;
}

.uploadFile{
    display: none;
}
.upload-clic{
    text-align: center;
    background: rgb(30,30,30) ;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    padding: 0.35em;
}

.participants-info{
    text-align: center;
    font-size: 0.90em;
}

.link_register {
    display: flex;
    justify-content: center;
}