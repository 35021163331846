.titleLeagueLeaderboard{
    color: #f3f3f3;
}

.subtitleLeagueLeaderboard{
    background-color: var(--bg-containers);
    padding: 1%;
    border-radius: 0.625rem;

}
.containerLeagueLeaderboard{
    width: 80%;
    padding: 0.3% 0.3% 0.3% 1%;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

.spacignLL{
    width: 18%;

}
.spacingColumnLL{
    width: 18%;
}

.spacignLL, .spacingColumnLL{
    text-align: center;
    color:var(--titles-color)
}
