.selectInitial{

  display: flex;
  gap:2px;
  cursor: pointer;
  user-select: none;
  justify-content: center;
}
.iconSelectOption{
  font-size: 1.6875rem;
  color:#777777;
}
.overlay{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparent;
  z-index:1;   
}
.optionsWinner{
  z-index: 2;
  position: absolute;
  background-color: #292929;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px #181818;
  padding: 4%;
  width: 7.6875rem;
  left: 28%;
}
.textOption{
  cursor: pointer;
  color: var(--titles-color)
}
.positionRelative{
  position: relative;
}