*{
    padding: 0;
    margin: 0;
    color: #f3f3f3;
}

.team{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .4rem;
}

.team_image_div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
}

.team_image{
    border-radius: 10px;
    width: 50%;
}

.componentContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.componentContainerLeft{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
}

.teammates{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    gap: 0.5rem;
}

.teammatesRight{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    text-align: right;
    gap: 0.5rem;
}

.marker{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.marker button{
    background-color: #ff6719;
    border-radius: 5px;
    border: none;
    padding: 7px 8px;
    display: flex;
    cursor: pointer;
}

.iconsTeam{
    font-size: 25px;
}

.removeShadow{
    box-shadow: none;
}

.baseContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}